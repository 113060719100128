import React from 'react';
import { styled } from 'linaria/react';
import { cx } from 'linaria';
import { InputBox } from './Components/InputBox';

const ItemWrapper = styled('div')`
  justify-content: space-between;
  position: relative;
  width: 100%;
  background: transparent;
  display: flex;
  align-items: center;
  :hover,
  :focus {
    outline: none;
  }
  .name {
  }
  .count {
    text-align: right;
  }
`;

export const ListFilterItem = ({ item, name, className }) => {
  return (
    <ItemWrapper
      className={cx(
        item.isActive ? 'selected list-item' : 'list-item',
        className
      )}
    >
      <InputBox
        checked={item.isActive}
        text={item.text}
        id={'filter' + name + item.text}
      />
      <span className="count">({item.resultCount})</span>
    </ItemWrapper>
  );
};
