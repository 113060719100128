import PaginationContext from '@jetshop/core/components/Pagination/PaginationContext';
import { PaginationBehaviour } from '@jetshop/ui/Pagination';
import { PaginationWrapper } from '../CategoryPage/Pagination/PaginationWrapper';
import ProductGrid from '../CategoryPage/ProductGrid';
import React from 'react';
import { styled } from 'linaria/react';
import Filter from '../CategoryPage/Filters';
import { ProductNumber } from '../CategoryPage/StandardCategoryPage';
import { SearchHeader } from './SearchHeader';
import { PagePadding } from '../Layout/MaxWidth';
import { ContentPadding } from '../Layout/Content/Content';

const Wrapper = styled('div')`
  ${ContentPadding};
`;

const SearchResults = ({ loading, ...props }) => {
  const { total, term, result } = props;
  const products = result?.data?.search?.products?.result;
  return (
    <PaginationContext.Consumer>
      {({ currentPage, goToPage, perPage }) => (
        <Wrapper>
          <PagePadding>
            <SearchHeader
              total={total}
              term={term}
              loading={loading}
              currentPage={currentPage}
            />
            {(products || currentPage > 1) && (
              <>
                {props.filters && props.filters.length > 0 && (
                  <Filter filters={props.filters} />
                )}
                <ProductNumber total={props.total} />
                <ProductGrid
                  listName="search"
                  products={products}
                  loading={loading}
                />
                <PaginationBehaviour
                  currentPage={currentPage}
                  goToPage={goToPage}
                  perPage={perPage}
                  total={props.total}
                >
                  {paginationProps => (
                    <PaginationWrapper {...paginationProps} />
                  )}
                </PaginationBehaviour>
              </>
            )}
          </PagePadding>
        </Wrapper>
      )}
    </PaginationContext.Consumer>
  );
};

export default SearchResults;
