import t from '@jetshop/intl';
import React from 'react';
import { styled } from 'linaria/react';
import MaxWidth from '../Layout/MaxWidth';

const Header = styled('header')`
  background: white;
`;

const Matches = styled('div')`
  text-align: center;
`;

const Container = styled(MaxWidth)``;

export const SearchHeader = ({ loading, currentPage, term, total }) => {
  return (
    <Header>
      <Container>
        <Matches>
          <h2>{t('Search result')}</h2>
          {loading ? (
            currentPage === 1 ? (
              <div>
                {t(`Loading search results for {term}…`, {
                  term
                })}
              </div>
            ) : (
              <div>
                {t(`Loading page {currentPage}…`, {
                  currentPage
                })}
              </div>
            )
          ) : (
            <div>
              {t(
                '{total, plural, =1 {# item} other {# items}} matching for "{term}"',
                {
                  total,
                  term
                }
              )}
            </div>
          )}
        </Matches>
      </Container>
    </Header>
  );
};
