import { ReactComponent as Carot } from '@jetshop/ui/svg/Carrot.svg';
import { css, cx } from 'linaria';
import React from 'react';

const carot = css`
  transition: transform 200ms;
  transform: rotate(-90deg);
  &.is-open {
    transform: rotate(0);
  }
`;

export function ActiveCarot({ isOpen }) {
  return <Carot className={cx(carot, isOpen ? 'is-open' : null)} />;
}
