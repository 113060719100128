import React from 'react';
import Checkbox from '@jetshop/ui/Checkbox';
import { useBooleanFilter } from '@jetshop/core/hooks/Filters/useBooleanFilter';
import { styled } from 'linaria/react';
import { FilterNameTag } from './ListFilters';

const BooleanFiltersWrapper = styled('div')`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
`;
const BooleanFilterWrapper = styled('div')`
  label {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    font-size: inherit !important;
    &:hover input ~ span {
      background-color: #eee;
    }
    svg {
      use {
        fill: black;
      }
      path {
        fill: black;
      }
    }
  }
`;

export function BooleanFilters({ filters, className }) {
  if (!filters) return null;

  const boolFilters = filters.filter(
    filter => filter.__typename === 'BooleanFilter'
  );

  if (boolFilters.length === 0) return null;

  return (
    <BooleanFiltersWrapper className={className}>
      {boolFilters.map(filter => (
        <BooleanFilter filter={filter} key={filter.id} />
      ))}
    </BooleanFiltersWrapper>
  );
}

function BooleanFilter({ filter }) {
  const { apply } = useBooleanFilter({ filter });
  return (
    <BooleanFilterWrapper>
      <FilterNameTag>
        <Checkbox
          key={filter.id}
          label={filter.name}
          checked={filter.value}
          onChange={e => {
            apply({ value: e.currentTarget.checked });
          }}
        />
      </FilterNameTag>
    </BooleanFilterWrapper>
  );
}
