import { useListFilter } from '@jetshop/core/hooks/Filters/useListFilter';
import t from '@jetshop/intl';
import {
  DropdownMenuButton as Button,
  DropdownMenuItem,
  DropdownMenuItems as Items
} from '@jetshop/ui/DropdownMenu';
import ErrorBoundary from '@jetshop/ui/ErrorBoundary/Generic';
import PassThrough from '@jetshop/ui/ErrorBoundary/PassThrough';
import { ReactComponent as Carot } from '@jetshop/ui/svg/Carrot.svg';
import React from 'react';
import { FilterDropdownMenu } from './FilterDropdownMenu';
import { ListFilterItem } from './ListFilterItem';
import { styled } from 'linaria/react';
import { theme } from '../../Theming/Theming';

export const FilterNameTag = styled(theme.categoryPage.filter.name.tag)``;

export function ListFilters({ filters }) {
  const listFilters = filters.filter(
    filter => filter.__typename === 'ListFilter'
  );

  if (listFilters.length === 0) return null;

  return listFilters.map(filter => (
    <ErrorBoundary component={PassThrough} key={filter.id}>
      <ListFilter filter={filter} />
    </ErrorBoundary>
  ));
}

function ListFilter({ filter }) {
  const { apply, clear } = useListFilter({ filter });
  const onSelect = value => () => apply({ value });
  const activeItems = filter.items.filter(item => {
    return item.isActive;
  });

  const sortingItems = filter.items
    .slice()
    .sort((a, b) =>
      parseFloat(a.value.replace(',', '.')) >
      parseFloat(b.value.replace(',', '.'))
        ? 1
        : -1
    );

  return (
    <FilterDropdownMenu>
      <Button className={filter.hasActiveItems ? 'active' : null}>
        <FilterNameTag className="filter-name">
          {filter.name}
          {filter.hasActiveItems && (
            <span className="filter-active-count">({activeItems.length})</span>
          )}
        </FilterNameTag>
        <Carot className="carot" />
      </Button>
      <Items as="ul">
        {sortingItems.map(item => (
          <DropdownMenuItem
            key={item.id}
            onSelect={item.resultCount > 0 && onSelect(item.value)}
            style={
              item.resultCount === 0
                ? {
                    opacity: 0.4,
                    cursor: 'not-allowed'
                  }
                : {}
            }
          >
            <ListFilterItem item={item} name={filter.name} />
          </DropdownMenuItem>
        ))}
        <>
          {filter.hasActiveItems && (
            <DropdownMenuItem
              className="clear-dropdown"
              onSelect={({ setIsOpen }) => {
                setIsOpen(false);
                clear();
              }}
            >
              {t('Clear filter')}
            </DropdownMenuItem>
          )}
        </>
      </Items>
    </FilterDropdownMenu>
  );
}
