import t from '@jetshop/intl';
import Accordion from '@jetshop/ui/Accordion/Accordion';
import { BooleanFilters } from '../BooleanFilters';
import { ListAccordion } from './ListAccordion';
import { RangeAccordion } from './RangeAccordion';
import { theme } from '../../../Theming/Theming';
import React, { Fragment } from 'react';
import { styled } from 'linaria/react';
import CloseButton from '../../../ui/CloseButton';
import { useFilters } from '@jetshop/core/hooks/Filters/useFilters';
import Button from '../../../ui/Button';
import { SortList } from './SortList';

const Title = styled('h3')`
  border-bottom: 1px solid ${theme.colors.border};
  text-align: center;
  padding: 1.5rem;
`;

const Wrapper = styled('div')`
  max-height: 80vh;
`;

const Listing = styled('div')`
  .mobile-bool-filters-wrapper {
    padding: 1rem;
    flex-wrap: wrap;
    justify-content: flex-start;
    label {
      margin-bottom: 0.5rem;
    }
    > div:not(:last-child) {
      margin-right: 1rem;
    }
  }
`;
const Header = styled('div')``;

const StyledCloseButton = styled(CloseButton)`
  position: absolute;
  right: 14px;
  top: 13px;
  margin: 0 !important;
  padding: 0 !important;
  background: white !important;
`;

const DrawerButton = styled(Button)`
  width: calc(100% - 2rem);
  margin: 0.5rem 1rem;
  height: 40px;
  &.show-all {
  }
`;

const FilterDrawer = ({ filters, sortOrders, close, ...props }) => {
  const listFilters = (filters || []).filter(
    filter => filter.__typename === 'ListFilter'
  );

  const { clearAllFilters } = useFilters();

  return (
    <section {...props}>
      {filters ? (
        <Wrapper>
          <Header>
            <Title>{t('Filter')}</Title>
            <StyledCloseButton onClick={close} />
          </Header>
          <Listing>
            <Accordion single>
              {accordionProps => {
                return (
                  <React.Fragment>
                    <ListAccordion
                      filters={filters}
                      accordionProps={accordionProps}
                    />
                    <RangeAccordion
                      filters={filters}
                      accordionProps={accordionProps}
                      startingIdx={listFilters.length}
                    />
                  </React.Fragment>
                );
              }}
            </Accordion>
            <BooleanFilters
              filters={filters}
              className="mobile-bool-filters-wrapper"
            />
          </Listing>
          <Fragment>
            <DrawerButton hollow onClick={clearAllFilters}>
              {t('Clear all filters')}
            </DrawerButton>
            <DrawerButton className="show-all" onClick={close}>
              {t('Show products')}
            </DrawerButton>
          </Fragment>
        </Wrapper>
      ) : (
        <Wrapper>
          <Header>
            <Title>{t('Sort by')}</Title>
            <StyledCloseButton onClick={close} />
          </Header>
          <Listing className="sortBy" onClick={close}>
            <SortList sortOrders={sortOrders} />
          </Listing>
        </Wrapper>
      )}
    </section>
  );
};

export default FilterDrawer;
