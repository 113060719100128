import React from 'react';
import { Query } from 'react-apollo';
import CategoryGrid from './CategoryGrid';
import { styled } from 'linaria/react';
import categoryListQuery from './CategoryListQuery.gql';

const CategoryListWrapper = styled('div')`
  margin-top: 4rem;
`;

const CategoryList = () => {
  return (
    <Query
      query={categoryListQuery}
      variables={{
        levels: 1
      }}
    >
      {({ data, loading }) =>
        !loading && data ? (
          <CategoryListWrapper>
            <CategoryGrid categories={data.categories} />
          </CategoryListWrapper>
        ) : null
      }
    </Query>
  );
};

export default CategoryList;
