import t, { useIntl } from '@jetshop/intl';
import React, { useMemo } from 'react';
import { styled } from 'linaria/react';
import CategoryHeader from '../CategoryPage/CategoryHeader';
import { PagePadding } from '../Layout/MaxWidth';
import CategoryHeaderLoadingState from './CategoryHeaderLoadingState';
import Filters from './Filters';
import ProductGridWindow from './ProductGridWindow';
import routeQuery from '../RouteQuery.gql';
import useInfinitePagination from '@jetshop/core/hooks/useInfinitePagination';
import { useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import qs from 'qs';
import { ContentPadding } from '../Layout/Content/Content';
import RightIcon from '../ui/icons/RightIcon';
import { theme } from '../Theming/Theming';

const Container = styled.div`
  ${ContentPadding}
`;

const CategoryWrapper = styled.div`
  margin-bottom: 3rem;
`;

const LoadMoreButtonWrapper = styled.div`
  width: 100%;
  max-width: 16rem;
  margin: 1rem auto;
`;

const StyledButtonLink = styled(Link)`
  position: relative;
  svg {
    transform: rotate(90deg);
    position: absolute;
    right: 1rem;
  }
  &.up {
    svg {
      transform: rotate(-90deg);
    }
  }
`;

const LoadingProductWrapper = styled.div`
  margin: 2rem auto;
  text-align: center;
  p {
    color: ${theme.colors.greys[4]};
    font-size: 0.9rem;
  }
  .loadingbar {
    margin: 0.5rem auto 0;
    position: relative;
    width: 200px;
    background: ${theme.colors.greys[2]};
    height: 2px;
  }
`;

const Progress = styled.div`
  background: ${theme.colors.primary};
  position: absolute;
  left: 0;
  top: 0;
  height: 2px;
`;

export const LoadingProductDisplay = ({ amount = 1, total = 1 }) => {
  const t = useIntl();
  if (total === 0) return null;
  return (
    <LoadingProductWrapper percent={amount / total}>
      <p>
        {t('You have seen {amount} out of {total} products', {
          amount,
          total
        })}
      </p>
      <div className="loadingbar">
        <Progress
          className="progress"
          style={{ width: (amount / total) * 100 + '%' }}
        />
      </div>
    </LoadingProductWrapper>
  );
};

export const LoadMoreButton = ({ ...props }) => {
  return (
    <LoadMoreButtonWrapper className={props.className}>
      <StyledButtonLink {...props}>
        {t('Show more')}
        <RightIcon />
      </StyledButtonLink>
    </LoadMoreButtonWrapper>
  );
};

const WindowedCategory = ({ category, result }) => {
  const { previous, next, products } = useInfinitePagination({
    result,
    query: routeQuery
  });

  const location = useLocation();
  const params = qs.parse(location.search, { ignoreQueryPrefix: true });

  const routePath = category?.isDynamic ? null : result?.data?.route?.path;
  const categoryPath = useMemo(() => routePath && { path: routePath }, [
    routePath
  ]);

  const standardCategory =
    category?.products?.filters.length > 0 ||
    category?.products?.totalResults > 0;

  if (!category) {
    return (
      <CategoryWrapper>
        <PagePadding>
          <CategoryHeaderLoadingState />
        </PagePadding>
      </CategoryWrapper>
    );
  }

  return (
    <CategoryWrapper>
      <CategoryHeader
        category={category}
        breadcrumbProps={{
          parents: result?.data?.route?.parents,
          breadcrumbs: result?.data?.route?.breadcrumbs
        }}
        standardCategory={standardCategory}
      />
      <Filters
        filters={category.products.filters || []}
        sortOrders={category.products.sortOrders || []}
        total={category.products.totalResults}
      />
      <PagePadding>
        <Container>
          {previous.hasProducts && (
            <LoadMoreButton
              className="up"
              onClick={e => {
                e.preventDefault();
                previous.fetchProducts();
              }}
              to={{
                ...location,
                search: qs.stringify({
                  ...params,
                  page: previous.page
                })
              }}
            />
          )}
          <ProductGridWindow
            id="category"
            prevOffset={previous.offset}
            products={products}
            listName={category.name}
            categoryPath={categoryPath}
            loading={result.loading}
            imageSizes={[1 / 2, 1 / 2, 1 / 3, 1 / 4]}
          />
          <LoadingProductDisplay
            amount={products.length}
            total={category.products.totalResults}
          />
          {next.hasProducts && (
            <LoadMoreButton
              onClick={e => {
                e.preventDefault();
                next.fetchProducts();
              }}
              to={{
                ...location,
                search: qs.stringify({
                  ...params,
                  page: next.page
                })
              }}
            />
          )}
        </Container>
      </PagePadding>
    </CategoryWrapper>
  );
};

export default WindowedCategory;
