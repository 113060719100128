import t from '@jetshop/intl';
import { Above } from '@jetshop/ui/Breakpoints';
import Drawer, { DrawerTarget, DrawerTrigger } from '@jetshop/ui/Modal/Drawer';
import { ReactComponent as Carrot } from '@jetshop/ui/svg/Carrot.svg';
import React, { Fragment, useEffect, useState } from 'react';
import { styled } from 'linaria/react';
import { ActiveFilters } from './ActiveFilters';
import { BooleanFilters } from './BooleanFilters';
import FilterDrawer from './Mobile/FilterDrawer';
import { ListFilters } from './ListFilters';
import { RangeFilters } from './RangeFilters';
import Button from '../../ui/Button';
import ListSorting from './ListSorting';
import { theme } from '../../Theming/Theming';

const FilterWrapper = styled('div')`
  margin: 0 0 1.5rem 0;
`;

const MobileButtons = styled('div')`
  display: flex;
  flex-direction: row;
  background: ${theme.colors.white};
  height: 40px;
  transition: all 0.3s;
  &[data-scroll='true'] {
    position: fixed;
    height: 36px;
    top: ${theme.mobileHeader.height.scroll + 'px'};
    left: 0;
    width: 100%;
    z-index: 90;
    border-bottom: 1px solid ${theme.colors.border};
    button {
      border: none;
      margin: 0;
      &:first-child:not(:last-child) {
        border-right: 1px solid ${theme.colors.border};
      }
    }
  }
`;

const MobileButton = styled(Button)`
  width: 100%;
  height: 100%;
  &:first-of-type {
    margin-right: 0.5rem;
  }
  svg {
    display: none;
    transform: rotate(-90deg);
  }
`;

const DesktopFilter = styled('div')``;

const FilterStandard = styled('div')`
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 60px;
  background: ${theme.colors.white};
  transition: all 0.3s;
  //FILTER ITEMS
  > div {
    transition: all 0.3s;
    width: 100%;
    position: relative;
    margin: 0 0 0 0;
    display: flex;
    border: 1px solid ${theme.categoryPage.filter.borderColor};
    &:not(:last-child) {
      border-right: none;
    }
  }
  &[data-scroll='true'] {
    position: fixed;
    width: 100%;
    left: 0;
    top: ${theme.desktopHeader.height.scroll + 'px'};
    z-index: 90;
    > div {
      border-color: ${theme.colors.border};
      &:first-child {
        border-left: none;
      }
      &:last-child {
        border-right: none;
      }
    }
  }
`;

const Filter = ({ filters, sortOrders, result }) => {
  const [scroll, setScroll] = useState(false);
  useEffect(() => {
    if (typeof window !== 'undefined')
      window.addEventListener('scroll', handleScroll);
    return () => {
      if (typeof window !== 'undefined')
        window.removeEventListener('scroll', handleScroll);
    };
  });

  const handleScroll = event => {
    if (document.getElementById('filter-wrapper')) {
      const filterTop =
        document.getElementById('filter-wrapper').offsetTop -
        document.getElementById('main-header').offsetHeight;
      if (window.scrollY < filterTop && scroll) {
        setScroll(false);
      } else if (window.scrollY > filterTop && !scroll) {
        setScroll(true);
      }
    }
  };

  const showFilter = filters && filters.length > 0;
  const showSorting = sortOrders && sortOrders.length > 0;

  if (!showFilter && !showSorting) return null;

  return (
    <FilterWrapper id="filter-wrapper">
      <Above breakpoint="lg">
        {matches =>
          matches ? (
            <DesktopFilter>
              {scroll && <FilterStandard />}
              <FilterStandard data-scroll={scroll}>
                {showFilter && (
                  <>
                    <ListFilters filters={filters} />
                    <RangeFilters filters={filters} />
                    <BooleanFilters filters={filters} />
                  </>
                )}
                {showSorting && <ListSorting sortOrders={sortOrders} />}
              </FilterStandard>
              <ActiveFilters filters={filters} />
            </DesktopFilter>
          ) : (
            <Fragment>
              {scroll && <MobileButtons />}
              <MobileButtons data-scroll={scroll}>
                {showFilter && (
                  <DrawerTrigger
                    id="filterDrawer"
                    coverStyles={{ zIndex: 105 }}
                  >
                    {({ showTarget }) => (
                      <MobileButton hollow onClick={showTarget}>
                        {t('Filter')}
                        <Carrot />
                      </MobileButton>
                    )}
                  </DrawerTrigger>
                )}

                {showSorting && (
                  <DrawerTrigger id="sortDrawer" coverStyles={{ zIndex: 105 }}>
                    {({ showTarget }) => (
                      <MobileButton hollow onClick={showTarget}>
                        {t('Sort By')}
                        <Carrot />
                      </MobileButton>
                    )}
                  </DrawerTrigger>
                )}
              </MobileButtons>

              {showFilter && (
                <DrawerTarget id="filterDrawer">
                  {({ hideTarget, isOpen }) => (
                    <Drawer isOpen={isOpen} left>
                      <FilterDrawer close={hideTarget} filters={filters} />
                    </Drawer>
                  )}
                </DrawerTarget>
              )}

              {showSorting && (
                <DrawerTarget id="sortDrawer">
                  {({ hideTarget, isOpen }) => (
                    <Drawer isOpen={isOpen} right>
                      <FilterDrawer
                        close={hideTarget}
                        sortOrders={sortOrders}
                      />
                    </Drawer>
                  )}
                </DrawerTarget>
              )}
            </Fragment>
          )
        }
      </Above>
    </FilterWrapper>
  );
};

export default Filter;
