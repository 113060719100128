import React from 'react';
import { styled } from 'linaria/react';
import { InputBox } from './Components/InputBox';

const ItemWrapper = styled('div')`
  display: flex;
  align-items: center;
`;

export const ListSortingItem = ({ selected, item, className }) => {
  return (
    <ItemWrapper className={className}>
      <InputBox
        checked={selected}
        text={item.text}
        id={'sorting' + item.text}
      />
    </ItemWrapper>
  );
};
