import { styled } from 'linaria/react';
import React from 'react';
import { useListFilter } from '@jetshop/core/hooks/Filters/useListFilter';
import { ActiveCarot } from './ActiveCarot';
import { ListFilterItem } from '../ListFilterItem';
import { theme } from '../../../Theming/Theming';

export const AccordionButton = styled('div')`
  display: flex;
  padding: 1em;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid ${theme.colors.border};
  .count {
    margin-left: 5px;
  }
`;

export const DrawerSubTitle = styled('h4')``;

export const AccordionContentWrapper = styled('div')`
  border-bottom: 1px solid ${theme.colors.border};
`;

const List = styled('ul')`
  list-style: none;
  li {
    &:not(:first-child) {
      border-top: 1px solid ${theme.colors.white};
    }
    button {
      padding: 1em;
      width: 100%;
      display: flex;
      background: ${theme.colors.greys[0]};
    }
  }
`;

export const ListAccordion = ({ filters, accordionProps, startingIdx = 0 }) => {
  if (!filters) return null;

  const listFilters = filters.filter(
    filter => filter.__typename === 'ListFilter'
  );

  return (
    <>
      {listFilters.map((filter, idx) => (
        <ListFilter
          key={filter.id}
          filter={filter}
          idx={idx}
          startingIdx={startingIdx}
          accordionProps={accordionProps}
        />
      ))}
    </>
  );
};

function ListFilter({ filter, idx, accordionProps, startingIdx }) {
  const { apply } = useListFilter({ filter });
  const { AccordionContent } = accordionProps;
  const accordionIsOpen = accordionProps.openIndexes.includes(
    idx + startingIdx
  );
  const activeItems = filter.items.filter(item => {
    return item.isActive;
  });
  return (
    <>
      <AccordionButton onClick={() => accordionProps.handleClick(idx)}>
        <DrawerSubTitle className="name">
          {filter.name}
          {filter.hasActiveItems && (
            <span className="count">({activeItems.length})</span>
          )}
        </DrawerSubTitle>
        <ActiveCarot
          isActive={filter.hasActiveItems}
          isOpen={accordionIsOpen}
        />
      </AccordionButton>
      <AccordionContent isOpen={accordionIsOpen}>
        <AccordionContentWrapper>
          <List>
            {filter.items.map(item => (
              <li key={item.id}>
                <button onClick={() => apply({ value: item.value })}>
                  <ListFilterItem item={item} />
                </button>
              </li>
            ))}
          </List>
        </AccordionContentWrapper>
      </AccordionContent>
    </>
  );
}
