import SortOrder from '@jetshop/ui/SortOrder/SortOrder';
import React from 'react';
import { styled } from 'linaria/react';
import { ListSortingItem } from '../ListSortingItem';
import { AccordionButton } from './ListAccordion';

const ListWrapper = styled('div')`
  .mobile-item {
    font-size: 0.9rem;
  }
`;

export const SortList = ({ sortOrders }) => {
  return (
    <SortOrder sortOrders={sortOrders}>
      {({ changeSortOrder, direction, sortOrder, mappedSortOrder }) => {
        return (
          <ListWrapper>
            {mappedSortOrder.map((item, index) => {
              return (
                <AccordionButton
                  key={index}
                  onClick={() => {
                    if (
                      item.direction === direction &&
                      item.value === sortOrder
                    )
                      changeSortOrder(null, null);
                    else changeSortOrder(item.value, item.direction);
                  }}
                >
                  <ListSortingItem
                    selected={
                      item.direction === direction && item.value === sortOrder
                    }
                    item={item}
                    className={'mobile-item'}
                  />
                </AccordionButton>
              );
            })}
          </ListWrapper>
        );
      }}
    </SortOrder>
  );
};
