import PaginationContext from '@jetshop/core/components/Pagination/PaginationContext';
import t from '@jetshop/intl';
import { PaginationBehaviour } from '@jetshop/ui/Pagination';
import { PaginationWrapper } from './Pagination/PaginationWrapper';
import React from 'react';
import { styled } from 'linaria/react';
import CategoryHeader from '../CategoryPage/CategoryHeader';
import CategoryHeaderLoadingState from './CategoryHeaderLoadingState';
import Filters from './Filters';
import ProductGrid from './ProductGrid';
import { PagePadding } from '../Layout/MaxWidth';
import { ContentPadding } from '../Layout/Content/Content';
import GetStartPage from '../StartPage/GetStartPage';
import { ContentRender } from '../ContentRender/ContentRender';

const Container = styled('div')`
  ${ContentPadding}
`;

const ContentRenderWrapper = styled('div')`
  margin-bottom: 3rem;
`;

const CategoryWrapper = styled('div')``;

const ProductNumberText = styled('p')`
  text-transform: uppercase;
  color: #878787;
  letter-spacing: 0.5px;
  margin-bottom: 0.5rem;
  font-size: 0.9rem;
`;

export const ProductNumber = ({ total }) => {
  return (
    <ProductNumberText>
      {t('{total, plural, =1 {# product} other {# products}}', {
        total
      })}
    </ProductNumberText>
  );
};

const Category = ({ category, result }) => {
  if (!category) {
    return (
      <CategoryWrapper>
        <PagePadding>
          <CategoryHeaderLoadingState />
        </PagePadding>
      </CategoryWrapper>
    );
  }

  const startPageId = parseInt(category.mainHeader, 10) || 0;

  const standardCategory =
    category?.products?.filters.length > 0 ||
    category?.products?.totalResults > 0;

  //FILTER CONTENT ITEMS OVE OR UNDER PRODUCTS
  let items = [];
  let underItems = [];
  category?.data?.items.forEach(item => {
    if (item?.name?.search('under-') !== -1) {
      underItems.push(item);
    } else {
      items.push(item);
    }
  });

  return (
    <CategoryWrapper>
      <PagePadding>
        <CategoryHeader
          category={category}
          content={{ ...category.data, items }}
          breadcrumbProps={{
            parents: result?.data?.route?.parents,
            breadcrumbs: result?.data?.route?.breadcrumbs
          }}
        />
        {startPageId > 0 && <GetStartPage startPageId={startPageId} />}
        <Container>
          <Filters
            filters={category.products?.filters || []}
            sortOrders={category.products?.sortOrders || []}
            result={result}
          />
          {standardCategory && (
            <ProductNumber total={category.products.totalResults} />
          )}
          <ProductGrid
            products={category.products.result}
            listName={category.name}
            categoryPath={category.isDynamic ? null : result?.data?.route}
            loading={result.loading}
            imageSizes={[1 / 2, 1 / 2, 1 / 3, 1 / 4]}
          />

          <PaginationContext.Consumer>
            {props => {
              return (
                <PaginationBehaviour
                  {...props}
                  total={category.products.totalResults}
                >
                  {props => <PaginationWrapper {...props} />}
                </PaginationBehaviour>
              );
            }}
          </PaginationContext.Consumer>
        </Container>
      </PagePadding>
      <ContentRenderWrapper>
        <ContentRender data={{ ...category.data, items: underItems }} />
      </ContentRenderWrapper>
    </CategoryWrapper>
  );
};

export default Category;
