import React from 'react';
import { styled } from 'linaria/react';
import CategoryLink from '@jetshop/ui/CategoryLink';
import Image from '@jetshop/ui/Image';
import { theme } from '../../Theming/Theming';

const CategoryGridWrapper = styled('div')``;

const CategoryHeader = styled('h2')`
  margin-bottom: 1rem;
`;

const CategoryGridContainer = styled('ul')`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: stretch;
  margin: 0 -0.5rem;
`;

const CategoryItem = styled('li')`
  text-align: center;
  ${theme.below.sm} {
    width: 50%;
  }
  ${theme.only.sm} {
    width: 50%;
  }
  ${theme.only.md} {
    width: 25%;
  }
  ${theme.above.lg} {
    width: 20%;
  }
`;

const StyledCategoryLink = styled(CategoryLink)`
  display: flex;
  margin: 0.5rem;
  border: 1px solid ${theme.colors.border};
`;

const CategoryInner = styled('div')`
  width: 100%;
  &.no-image {
    //border: 1px solid ${theme.colors.border};
  }
`;

const CategoryImage = styled('div')`
  width: 100%;
`;

const CategoryInfo = styled('div')`
  padding: 1rem;
`;

const CategoryName = styled('h3')``;

const CatItem = ({ category }) => {
  const categoryImageUrl = category.images.length > 3 && category.images[4].url;
  return (
    <CategoryItem className="category-wrapper">
      <StyledCategoryLink category={category}>
        <CategoryInner className={categoryImageUrl ? '' : 'no-image'}>
          <CategoryImage>
            <Image
              src={categoryImageUrl}
              aspect="4:3"
              crop={true}
              cover
              sizes={[300]}
            />
          </CategoryImage>
          <CategoryInfo>
            <CategoryName>{category.name}</CategoryName>
          </CategoryInfo>
        </CategoryInner>
      </StyledCategoryLink>
    </CategoryItem>
  );
};
const CategoryGrid = ({ title, categories, className = '' }) => {
  return (
    <CategoryGridWrapper>
      {title && <CategoryHeader>{title}</CategoryHeader>}
      <CategoryGridContainer>
        {categories.map(category => (
          <CatItem key={category.name} category={category} />
        ))}
      </CategoryGridContainer>
    </CategoryGridWrapper>
  );
};

export default CategoryGrid;
