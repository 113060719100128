import { styled } from 'linaria/react';
import React from 'react';
import { DropdownMenu } from '@jetshop/ui/DropdownMenu';
import { theme } from '../../Theming/Theming';

export const DropDownButton = ``;

export const DropDownMenuItems = `
  top: 100%;
  border-top: 0;
  border-radius: 0 0 3px 3px;
  z-index: 50;
  width: 100%;
  min-width: 260px;
  background: white;
  border: 1px solid ${theme.colors.border};
  box-shadow: 2px 2px 3px #0000001f;
  &.right {
    left: auto;
    right: 0;
  }
`;

export const DropDownMenuItem = `
  height: 40px;
  line-height: 40px;
  padding: 0 10px;
  border: none;
  padding: 0 10px;
  &:not(:last-child) {
    border-bottom: 1px solid ${theme.colors.borderLight};
  }
  :hover,
  :focus {
    outline: none;
    color: ${theme.categoryPage.filter.hoverColor};
  }
`;

const DropdownStyles = styled('div')`
  [data-flight-dropdown] {
    width: 100%;
    [data-flight-dropdown-button] {
      width: 100%;
      height: 100%;
      border: none;
      border-radius: 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
      svg {
        use {
          fill: ${theme.colors.font};
        }
      }
      .carot {
        margin-left: auto;
        transition: transform 200ms;
      }
      &[aria-expanded='true'] {
        .carot {
          transform: rotate(180deg);
        }
      }
    }
    [data-flight-dropdown-items] {
      ${DropDownMenuItems}
    }
    [data-flight-dropdown-item] {
      ${DropDownMenuItem}
    }
  }
`;

export function FilterDropdownMenu(props) {
  return (
    <DropdownStyles>
      <DropdownMenu {...props} />
    </DropdownStyles>
  );
}
