import { LoadingLine } from '@jetshop/ui/Loading/LoadingLine';
import React, { Fragment } from 'react';
import { styled } from 'linaria/react';

const backgroundColor = '#EEE';

const MockImage = styled('div')`
  padding-top: 33%;
  width: 100%;
  background: ${backgroundColor};
`;

const MockProductWrapper = styled('div')`
  display: flex;
  flex-wrap: wrap;
  margin: 0 -1%;
`;

const MockProduct = styled('div')`
  padding-top: 25%;
  width: 100%;
  background: ${backgroundColor};
  width: 23%;
  margin: 1%;
`;

export default React.memo(function CategoryHeaderLoadingState() {
  return (
    <Fragment>
      <MockImage />
      <LoadingLine
        color={backgroundColor}
        widthRem={20}
        heightPx={40}
        style={{
          margin: '1rem auto'
        }}
      />
      <LoadingLine
        color={backgroundColor}
        widthRem={10}
        heightPx={15}
        style={{
          width: '100',
          marginBottom: '20px'
        }}
      />
      <LoadingLine
        color={backgroundColor}
        widthRem={10}
        heightPx={25}
        style={{
          width: '100',
          marginBottom: '20px'
        }}
      />
      <MockProductWrapper>
        <MockProduct />
        <MockProduct />
        <MockProduct />
        <MockProduct />
      </MockProductWrapper>
    </Fragment>
  );
});
