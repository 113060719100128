import React from 'react';
import { styled } from 'linaria/react';
import { cx } from 'linaria';
import { theme } from '../../../Theming/Theming';

const InputBoxElement = styled('span')`
  position: relative;
  top: -1px;
  width: 14px;
  height: 14px;
  border: 1px solid #ddd;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 5px;
  background: ${theme.colors.white};
  &:before {
    width: 10px;
    height: 10px;
    position: relative;
    border-radius: 2px;
  }
  &[aria-checked='true'] {
    background: ${theme.colors.white};
    &:before {
      content: ' ';
      background: ${theme.categoryPage.filter.markerColor};
    }
  }
`;

const InputBoxWrapper = styled('span')`
  display: flex;
  align-items: center;
  label {
    cursor: pointer;
  }
  &:hover {
    ${InputBoxElement} {
      background: ${theme.colors.greys[0]};
    }
  }
`;

export const InputBox = ({ checked, className, text, id }) => {
  return (
    <InputBoxWrapper>
      <InputBoxElement
        role="checkbox"
        aria-checked={checked ? 'true' : 'false'}
        className={cx('input-box', className)}
        aria-labelledby={id}
      />
      {text && <label id={id}>{text}</label>}
    </InputBoxWrapper>
  );
};
