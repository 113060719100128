import { useTracker } from '@jetshop/core/analytics/Analytics';
import { trackListEvent } from '@jetshop/core/analytics/tracking';
import React, { useEffect, useMemo } from 'react';
import { css, cx } from 'linaria';
import { ProductCard } from './ProductCard';
import { priceStyle } from './ProductGrid';
import { WindowGrid } from '@jetshop/ui/WindowGrid';

const container = css`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 0 -0.5em 1em;
`;

const defaultStyle = css`
  flex: 0 0 auto;
  width: 25%;
`;

function ProductGridWindow({
  products,
  listName,
  loading,
  className,
  prevOffset,
  itemsPerRow,
  categoryPath,
  ...rest
}) {
  // Track product listing
  const track = useTracker();
  useEffect(() => {
    // Dont track anything if there are no products to render
    if (!products || products.length === 0) return;

    // Otherwise track a list view event
    track(trackListEvent({ listName, products }));
  }, [listName, products, track]);

  const renderProduct = useMemo(
    () => ({ item: product, style, innerRef }) => (
      <div className={defaultStyle} style={style} ref={innerRef}>
        <ProductCard
          key={product.articleNumber}
          product={product}
          as="div"
          categoryPath={categoryPath}
          loadImageEagerly={product.index < 4}
        />
      </div>
    ),
    [categoryPath]
  );

  return (
    <WindowGrid
      itemsPerRow={[2, null, 3, 4]}
      items={products}
      prevOffset={prevOffset}
      className={cx(container, priceStyle)}
      component={renderProduct}
    />
  );
}

export default ProductGridWindow;
