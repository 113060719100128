import t from '@jetshop/intl';
import SortOrder from '@jetshop/ui/SortOrder/SortOrder';
import React from 'react';
import {
  DropdownMenuButton,
  DropdownMenuItem,
  DropdownMenuItems
} from '@jetshop/ui/DropdownMenu';
import { FilterDropdownMenu } from './FilterDropdownMenu';
import { ReactComponent as Carot } from '@jetshop/ui/svg/Carrot.svg';
import { ListSortingItem } from './ListSortingItem';
import { FilterNameTag } from './ListFilters';

const ListSorting = ({ sortOrders }) => {
  return (
    <SortOrder sortOrders={sortOrders}>
      {({ changeSortOrder, direction, sortOrder, mappedSortOrder }) => {
        const selectedValue = mappedSortOrder.filter(item => {
          return item.direction === direction && item.value === sortOrder;
        });
        return (
          <FilterDropdownMenu>
            <DropdownMenuButton>
              <FilterNameTag className="sorting-name">
                {selectedValue.length ? selectedValue[0].text : t('Sort By')}
              </FilterNameTag>
              <Carot className="carot" />
            </DropdownMenuButton>
            <DropdownMenuItems className="right">
              {mappedSortOrder.map((item, index) => {
                return (
                  <DropdownMenuItem
                    key={index}
                    onClick={() => {
                      if (
                        item.direction === direction &&
                        item.value === sortOrder
                      )
                        changeSortOrder(null, null);
                      else changeSortOrder(item.value, item.direction);
                    }}
                  >
                    <ListSortingItem
                      selected={
                        item.direction === direction && item.value === sortOrder
                      }
                      item={item}
                    />
                  </DropdownMenuItem>
                );
              })}
            </DropdownMenuItems>
          </FilterDropdownMenu>
        );
      }}
    </SortOrder>
  );
};
export default ListSorting;
