import t from '@jetshop/intl';
import React from 'react';
import { Query } from 'react-apollo';
import BaseCategoryList from '../ui/CategoryList/CategoryList';
import { styled } from 'linaria/react';
import EmptySearchCategoriesQuery from './EmptySearchCategoriesQuery.gql';
import { SearchHeader } from './SearchHeader';
import { PagePadding } from '../Layout/MaxWidth';
import { ContentPadding } from '../Layout/Content/Content';

const Wrapper = styled('div')`
  ${ContentPadding};
`;

const CategoryList = styled(BaseCategoryList)`
  margin-top: 4rem;
  h3 {
    padding: 2rem;
  }
`;

const EmptySearchResults = ({ term, result }) => {
  return (
    <Wrapper>
      <PagePadding>
        <SearchHeader term={term} total={0} />
        <Query
          query={EmptySearchCategoriesQuery}
          variables={{
            levels: 1,
            includeHidden: false
          }}
        >
          {({ data, loading }) =>
            !loading && data ? (
              <CategoryList
                title={t('Shop by category')}
                categories={data.categories}
              />
            ) : null
          }
        </Query>
      </PagePadding>
    </Wrapper>
  );
};

export default EmptySearchResults;
